import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { sectionTitle } from "../../styles/common";
import HeaderImage from "../../components/common/HeaderImage";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  contentGreen: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(7),
    backgroundColor: "#093a1d",
  },
  presentationTitle: sectionTitle(theme),
  paragraph: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: 20,
  },
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    // fontSize: 20,
    textAlign: "center",
    color: "#FFFFFF",
  },

  paragraphWhite: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    // fontSize: 20,
    textAlign: "center",
    color: "#FFFFFF",
  },
  paragraphGreen: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
  },
  presentationTitleWhite: {
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: theme.spacing(6),
  },
  sectionMidGray: {
    backgroundColor: "#fafafa",
    height: "auto",
    display:"flex", // // // // // // 
    justifyContent:"center",
    alignItems:"center",
    padding: "60px 0px",
  },
  sectionMidWhite: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    display:"flex", // // // // // // 
    justifyContent:"center",
    alignItems:"center",
    padding: "60px 0px"
  },
  textMidGray: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    // width: "80%", // // // // // // 
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  textMidWhite: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "80%", // // // // // // 
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },

}));

const Main = () => {
  const classes = useStyles();
  return (
    <div>
      <HeaderImage text={"En el proceso de sanar necesitas conocerte, aceptarte y muchas veces reinventarte."} />

      <Grid container className={classes.contentGreen} justify={"center"}>
          <Grid item xs={12} md={10}>
              <Typography component={"p"} className={classes.subTitle}>SOBRE MÍ</Typography>

              <Typography variant={"h3"} className={classes.presentationTitleWhite}>¡Hola! es un placer conocerte</Typography>
            
              <Typography component={"p"} className={classes.paragraphWhite}>Mi nombre es Leydis Aponte, y para que sepas un poco más de mí te cuento que soy mujer, mamá, migrante venezolana que actualmente reside en Argentina y licenciada en Psicología.</Typography>
              <Typography component={"p"} className={classes.paragraphWhite}> De eso último te puedo decir que me gradué en la Universidad Metropolitana, y estudié en la Universidad Católica Andrés Bello mi postgrado de Psicología Clínica Comunitaria. En este también tiempo he podido especializarme en Terapia Cognitivo Conductual, Programación Neuro Lingüística y en Trastornos del Espectro del Autismo.</Typography>

          </Grid>
      </Grid>



      <Grid container justify={"center"}>

        <Grid item xs={12} md={6} className={classes.sectionMidGray}>
          <Grid item className={classes.textMidGray}>
            <Typography component={"p"} className={classes.paragraphGreen}>SOBRE PSICOSALUD Y VIDA</Typography>
            <Typography variant={"h3"} style={{marginBottom: "16px"}}>Mi centro terapéutico</Typography>
            <Typography variant={"h3"} >Psicosalud</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} className={classes.sectionMidWhite}>
          <Grid item className={classes.textMidWhite}>
            <Typography component={"p"} className={classes.paragraphGreen}>En el año 2014 tuve el inmenso honor de abrir las puertas de micentro terapéutico Psicosalud, lo que fué una experiencia maravillosa, con un equipo interdisciplinario de trabajo para el abordaje de patologías psicológicas y del desarrollo, así como la promoción de la salud mental, luego migré.</Typography>
            <Typography component={"p"}>Unos años después de llegar a Argentina, decidí crear una cuenta de instagram para aportar a la difusión de información para el conocimiento público acerca de la psicología, la salud mental, la enfermedad mental y los beneficios de la psicoterapia, teniendo como norte a quienes aún no tienen acceso a un espacio terapéutico.</Typography>
          </Grid>
        </Grid>  

      </Grid>


    </div>
  );
};

export default Main;
