import React, { useState } from "react";
import { Router, navigate, LocationProvider } from "@reach/router";
import {
  CssBaseline,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
  Button,
  IconButton,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Drawer,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Main from "./screens/Main/Main";
import Footer from "./components/Footer";
import Services from "./screens/Services";
import Articles from "./screens/Articles/Articles";
import Contact from "./screens/Contact/Contact";
import BookSection from "./screens/BookSection/BookSection";
import axios from "axios";

axios.defaults.baseURL =
  "https://us-central1-leydis-app.cloudfunctions.net/api";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const createSectionType = (route, text) => ({ text, route });

const sections = [
  createSectionType("/", "Home"),
  createSectionType("/servicios", "Servicios"),
  createSectionType("/articulos", "Articulos"),
  createSectionType("/libro", "Libro"),
  createSectionType("/contacto", "Contacto"),
];

const onClickLinkAndClose = (close) => (path) => () => {
  navigate(path);
  close();
};

const useStyle = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuItem: {
    color: "#093a1d", // // // // // // header
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuIcon: { color: "#093a1d" },
  drawerPaper: {
    width: 240,
  },
  appBar: { backgroundColor: "#fafafa", opacity: 1, color: "#093a1d" }, // // // // // // header
  main: { 
    // paddingBottom: theme.spacing(2), 
    flex: 1 
  },
  separator: { flex: 1 },
  listItemTextDrawer: { color: "#093a1d" },  // // // // // // drawer
}));



function App(props) {
  const theme = useTheme();
  const { container } = props;
  const classes = useStyle();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDawerClose = () => {
    setMobileOpen(false);
  };
  const onClickLink = onClickLinkAndClose(handleDawerClose);
    const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {sections.map(({ text, route }, index) => (
          <ListItem
            button
            onClick={onClickLink(route, handleDrawerToggle)}
            key={text}
          >
            <ListItemText primary={text} className={classes.listItemTextDrawer} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <LocationProvider>
      <>
        <CssBaseline />
        <HideOnScroll>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6">Leydis Aponte</Typography>
              <div className={classes.separator} />
              {sections.map((section, index) => (
                <Button
                  key={index}
                  onClick={onClickLink(section.route)}
                  className={classes.menuItem}
                >
                  {section.text}
                </Button>
              ))}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "left" : "right"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.main}>
          <Router>
            <Main path="/" />
            <Services path="/servicios" />
            <Articles path="/articulos" />
            <Contact path="/contacto" />
            <BookSection path="/libro" />
          </Router>
        </main>
        <Footer></Footer>
      </>
    </LocationProvider>
  );
}

export default App;
