import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import psicoEval from "../../img/evaluaciones.jpeg";
import psicoTherapy from "../../img/psicoterapias.jpeg";
import council from "../../img/ascesorias.jpeg";
// import ServicesItem from "./components/ServicesItem";
import { sectionTitle, containerStyles } from "../../styles/common";

const useStyles = makeStyles((theme) => ({
  container: containerStyles(theme),
  serviceTitle: sectionTitle(theme),


  contentNude: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(7),
    paddingTop: theme.spacing(15),
    backgroundColor: "#faf1e9",
  },
  contentGreen: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(7),
    backgroundColor: "#093a1d",
  },
  contentGreenLast: {
    paddingBottom: theme.spacing(6),
    backgroundColor: "#093a1d",
  },

  subTitleGreen: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(7),
    // fontSize: 20,
    textAlign: "center",
    color: "#093a1d",
  },
  presentationTitleGreen: {
    textAlign: "center",
    color: "#093a1d",
    marginBottom: theme.spacing(6),
  },
  paragraphWhite: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
    color: "#FFFFFF",
  },
  paragraphWhiteCoa: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
    color: "#FFFFFF",
    marginBottom: "25px",
  },
  sectionMidGray: {
    backgroundColor: "#093a1d",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"center",
    alignItems:"center",
    padding: "60px 0px",
  },
  sectionBefGreen: {
    backgroundColor: "#093a1d",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"flex-start",
    alignItems:"center",
    // paddingTop: "40px",
  },
  sectionLastGreen: {
    backgroundColor: "#093a1d",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"flex-start",
    alignItems:"center",
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: "25px",
    // },

  },
  sectionMidGrayTs: {
    backgroundColor: "#fafafa",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"center",
    alignItems:"center",
    padding: "60px 0px",
  },
  textMidGray: {
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    // width: "80%", // // // // // // 
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  textMidGreen: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    // width: "80%", // // // // // // 
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  paragraphGreen: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
  },
  sectionMidWhite: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    display:"flex", // // // // // // 
    justifyContent:"center",
    alignItems:"center",
    padding: "60px 0px"
  },
  textMidWhite: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "80%", // // // // // // 
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
      textAlign: "center",
    },
  },
  textMidWhiteCoa: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "80%", // // // // // // 
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  textLi: {
    textAlign: "left",
    marginLeft: "20px"
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  wrapImg: {
    marginBottom: "20px",
  },
  containerReverse: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    },
  },
  wrapImgCoa: {
    margin: "0px auto 35px auto",
  },

}));


const Services = () => {
  const classes = useStyles();
  return (
    <div>

        <Grid container className={classes.contentNude} justify={"center"}>
            <Grid item xs={12} md={10}>
                <Typography component={"p"} className={classes.subTitleGreen}>SERVICIOS</Typography>
                <Typography variant={"h4"} className={classes.presentationTitleGreen}>Te ofrezco atención individual en psicoterapia y evaluación psicológica, o grupal en sesiones de orientación, asesorías, talleres y charlas psicoeducativas en lo clínico y lo laboral.</Typography>
            </Grid>
        </Grid>


        <Grid container justify={"center"}>

            <Grid item xs={12} md={6} className={classes.sectionMidGray}>
                <Grid item xs={5} sm={4} className={classes.wrapImg}>
                    <img src={psicoTherapy} alt="img" className={classes.img} />
                </Grid>
                <Grid item className={classes.textMidGray}>
                    <Typography variant={"h4"}>Psicoterapia</Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.sectionMidWhite}>
                <Grid item className={classes.textMidWhite}>
                    <Typography component={"p"} className={classes.paragraphGreen}>La psicoterapia es un proceso que te lleva al autodescubrimiento y al crecimiento personal, para mejorar internamente y en tus relaciones interpersonales.</Typography>
                    <Typography component={"p"} className={classes.paragraphGreen}>De mi parte, tienes a tu disposición psicoterapia Cognitivo Conductual para infantes, adolescentes  y adultos/as, con herramientas de Psicoeducación, y Programación Neurolingüística. Atención individual, de pareja y familiar para el abordaje de temas derivados de diagnósticos previos, crisis vitales, autoconocimiento y otras situaciones:</Typography>
                    <Grid item className={classes.textLi}>
                        <Typography component={"li"}>Trabajo con trastornos de ansiedad</Typography>
                        <Typography component={"li"}>Trastornos del estado del ánimo</Typography>
                        <Typography component={"li"}>Trastornos de personalidad</Typography>
                        <Typography component={"li"}>Adicciones</Typography>
                        <Typography component={"li"}>Trastornos del neurodesarrollo</Typography>
                        <Typography component={"li"}>Duelos</Typography>
                        <Typography component={"li"}>"Acompañamiento psicológico en cuadros físicos.</Typography>
                        <Typography component={"li"}>Otros</Typography>
                    </Grid>                
                </Grid>
            </Grid>  

        </Grid>


        <Grid container justify={"center"} className={classes.containerReverse}>

            <Grid item xs={12} md={6} className={classes.sectionMidWhite}>
                <Grid item className={classes.textMidWhite}>
                    <Typography component={"p"} className={classes.paragraphGreen}>En algunos casos, para empezar a abordar un tema se hace necesario establecer un diagnóstico que nos permita avanzar. Para eso, te ofrezco la aplicación de instrumentos de evaluación psicométricos, screening y escalas específicas que nos otorgan resultados en las áreas de orientación vocacional, personalidad, neurodesarrollo, ansiedad y estado de ánimo.</Typography>
                    <Typography component={"p"} className={classes.paragraphGreen}>Después de un proceso de evaluación, me comprometo a la elaboración de informes psicológicos con Matrícula Profesional Venezolana, con diagnóstico por áreas evaluadas y recomendaciones generales y específicas para cada caso. Este informe también puede servir de guía para otros y otras profesionales que tengan contacto con tu caso y para docentes en el caso de niños y niñas.</Typography>       
                </Grid>
            </Grid>  

            <Grid item xs={12} md={6} className={classes.sectionMidGrayTs}>
                <Grid item xs={5} sm={4} className={classes.wrapImg}>
                    <img src={psicoEval} alt="img" className={classes.img} />
                </Grid>
                <Grid item className={classes.textMidGreen}>
                    <Typography variant={"h4"} style={{marginBottom: "10px"}}>Evaluación</Typography>
                    <Typography variant={"h4"}>Psicológica</Typography>
                </Grid>
            </Grid>

        </Grid>


        <Grid container className={classes.contentGreen} justify={"center"}>
            <Grid item xs={12} md={10}>
                <Grid item xs={4} sm={3} md={2} className={classes.wrapImgCoa}>
                    <img src={council} alt="img" className={classes.img} />
                </Grid>
                <Grid item className={classes.textMidGray}>
                    <Typography variant={"h4"}>Asesoria y Coaching</Typography>
                </Grid>
            </Grid>
        </Grid>


        <Grid container className={classes.contentGreenLast} justify={"center"}>

            <Grid item xs={12} md={6} className={classes.sectionBefGreen}>
                <Grid item className={classes.textMidWhiteCoa}>
                    <Typography component={"p"} className={classes.paragraphWhiteCoa}>Te ofrezco capacitaciones personalizadas en temáticas individuales y grupales, de índole laboral y personal.</Typography>
                    <Typography component={"p"} className={classes.paragraphWhite}>Escuela para Padres: encuentros semanales por un tiempo limitado para tratar diferentes temas que te ayuden en la crianza de tus hijos, dependiendo de su edad y etapa del desarrollo.</Typography>       
                    <Typography component={"p"} className={classes.paragraphWhite}>Comunicación Asertiva: abordamos en un encuadre de 1 hora por semana, temas relacionados con la escucha activa, la empatía, la asertividad y efectividad en el lenguaje, así como las habilidades de negociación y trasmisión de ideas, con herramientas de PNL.</Typography>
                </Grid>
            </Grid>  

            <Grid item xs={12} md={6} className={classes.sectionLastGreen}>
                <Grid item className={classes.textMidWhiteCoa}>
                    <Typography component={"p"} className={classes.paragraphWhite}>Equipos Diversos: Te brindo herramientas para el trabajo en equipo saludable y efectivo, orientado a objetivos y con esfuerzos conjuntos que aseguren la productividad y el buen clima laboral en todo momento.</Typography>
                    <Typography component={"p"} className={classes.paragraphWhite}>TEA(Trastornos del Espectro Autista): Diagnóstico y Pronóstico. Juntas y juntos encaramos el proceso que implica la llegada de los Trastornos del Espectro del Autismo en la familia; atravezando el diagnóstico propiamente dicho, el pronóstico, la necesidad el equipo terapéutico y el duelo propio del diagnostico.</Typography>       
                </Grid>
            </Grid>  

        </Grid>


    </div>
  );
};

export default Services;
