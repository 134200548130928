import React, { useCallback } from "react";
import {
  Grid,
  TextField,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";
import HeaderImage from "../../components/common/HeaderImage";
import { useState } from "react";
import { useCallAPI } from "../../hooks/callAPI";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  wrapContact: { 
    backgroundColor: "#FFFFFF"
  },
  textFields: { 
    // width: "100%",
    paddingRight: theme.spacing(2),
  },
  textFieldsLeft: { 
    paddingLeft: theme.spacing(2), 
  },
  textFieldCenter: { 
    marginTop: theme.spacing(6),
  },
  textFieldsGral: { 
    width: "100%",
  },
  wrapForm: { 
    maxWidth: "83.333333%",
    marginTop: theme.spacing(7),
    flexBasis: "83.333333%"
  },
  wrapButton: { 
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(8),
  },
  button: { 
    width: theme.spacing(20),
    fontWeight:"600",
  },

}));

const initialState = { name: "", email: "", messaje: "" };

const Contact = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialState);
  const [elementsBlur, setElementsBlur] = useState({});
  const { isLoading, requestData } = useCallAPI("/contactMe");
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      requestData({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
    },
    [formData, requestData]
  );

  const handleChange = useCallback(
    (e) => {
      setFormData({
        ...formData,

        // Trimming any whitespace
        [e.target.name]: e.target.value.trim(),
      });
    },
    [formData]
  );

  const handleBlur = useCallback(
    (e) => {
      setElementsBlur({ ...elementsBlur, [e.target.name]: true });
    },
    [elementsBlur]
  );

  const validations = useMemo(
    () => !formData.email || !formData.name || !formData.messaje,
    [formData]
  );
  const isValidEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
    formData.email
  );
  return (
    <Grid container justify={"center"} className={classes.wrapContact}>
      <HeaderImage text={"Contactame para consultas, asesorías o talleres"} />
      <Grid item align={"center"} className={classes.wrapForm}>
        <form
          onSubmit={handleSubmit}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <Grid container >

            {/* <Grid item xs={12}>
              <Typography variant={"h5"}>Para mayor informacion:</Typography>
            </Grid> */}

            <Grid item xs={6} className={classes.textFields}>
              <TextField
                value={formData.name}
                label="Nombre"
                name={"name"}
                className={classes.textFieldsGral}
              />
            </Grid>
            <Grid item xs={6} className={classes.textFieldsLeft} >
              <TextField
                value={formData.email}
                label="Email"
                name={"email"}
                className={classes.textFieldsGral}
                error={elementsBlur.email && !isValidEmail}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldCenter}>
              <TextField
                value={formData.messaje}
                label="Mensaje"
                multiline
                name={"messaje"}
                rows={4}
                className={classes.textFieldsGral}
                rowsMax={4}
              />
            </Grid>
            <Grid item xs={12} className={classes.wrapButton}>
              {elementsBlur.email &&
                elementsBlur.name &&
                elementsBlur.messaje && (
                  <Typography> Debes llenar todos los campos </Typography>
                )}
              {elementsBlur.email && !isValidEmail && (
                <Typography color={"secondary"}> Email invalido</Typography>
              )}
              <Button
                disabled={!isLoading || validations || !isValidEmail}
                type={"submit"}
                variant={"outlined"}
                className={classes.button}
              >
                Enviar
              </Button>
            </Grid>

            {/* <Grid item xs={12}>
              <b>Email:</b> psico.salud.contigo2015@gmail.com
            </Grid> */}

          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Contact;
