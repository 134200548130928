import React from "react";
import { navigate } from "@reach/router";
import { Grid, SvgIcon, Typography, makeStyles} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MediumIcon from "../../icons/MediumIcon";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  wrapFooter: {
    backgroundColor: "#093a1d",
    textAlign: "center",
    // minHeight:"250px",
    padding:"70px 80px 0px 80px",
    [theme.breakpoints.down("md")]: {
      padding:"70px 7% 0px 7%",
    },
    [theme.breakpoints.down("sm")]: {
      padding:"70px 8.3% 0px 8.3%",
    },
  },
  wrapTitle: { 
    textAlign:"left",
    [theme.breakpoints.down("sm")]: {
      textAlign:"center",
    },
  },
  titleFooter: { 
    color:"white"
  },
  wrapSocial: {
    marginTop:theme.spacing(4),
    marginBottom:theme.spacing(9),
    textAlign:"left",
    [theme.breakpoints.down("sm")]: {
      textAlign:"center",
    },
  },
  wrapRoutes: {
    display:"flex",
    color:"white",
    justifyContent:"space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop:theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      alignItems:"center",
      flexDirection: "column",
    },
  },
  textRoutes: {
    cursor: "pointer",
  }
}));

const createSectionType = (route, text) => ({ text, route });

const sections = [
  createSectionType("/", "HOME"),
  createSectionType("/servicios", "SERVICIOS"),
  createSectionType("/articulos", "ARTICULOS"),
  createSectionType("/libro", "LIBRO"),
  createSectionType("/contacto", "CONTACTO"),
];

const onClickLinkAndClose = (close) => (path) => () => {
  navigate(path);
  // close();
};

const onClickLink = onClickLinkAndClose(false);


const Footer = (props) => {
  const classes = useStyles();
  return (
    <Grid container   className={classes.wrapFooter}
    >
      <Grid item xs={12} md={6} className={classes.wrapTitle}>
        <Typography variant={"body1"} className={classes.titleFooter}>LEYDIS APONTE</Typography>
      </Grid>

      <Grid item xs={12}  md={6}>
        <Grid  item xs={12} className={classes.wrapRoutes}>

          {sections.map((section, index) => (
            <Typography
              className={classes.textRoutes}
              variant={"body1"}
              // button
              onClick={onClickLink(section.route)}
              key={index}
            >
              {section.text}
            </Typography>
          ))}

        </Grid>
        <Grid item xs={12} className={classes.wrapSocial}>
          <a href="https://www.linkedin.com/in/leyapontedavila/">
            <LinkedInIcon
              style={{ height: "30px", width: "30px", margin: "5px 30px 5px 0px", color: "white" }}
            />
          </a>
          <a href="https://medium.com/@leyaponte">
            <MediumIcon
              style={{
                height: "27px",
                width: "27px",
                marginBottom: "7px ",
                marginRight: "30px",
                color: "white" 
              }}
            />
          </a>
          <a href="https://www.instagram.com/psico.saludyvida/">
            <SvgIcon
              component={InstagramIcon}
              style={{ height: "30px", width: "30px", margin: "5px 5px 5px 0px", color: "white" }}
            />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
