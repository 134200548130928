import React, { useEffect } from "react";
import ArticleCard from "./ArticleCard";
import { useArticles } from "../../../hooks/articles";
import { makeStyles, Grid, CircularProgress } from "@material-ui/core";

const useState = makeStyles((theme) => ({ container: { width: "100%" } }));

const ArticlesList = () => {
  const classes = useState();
  const {
    isLoading,
    data: { data: articles },
    isError,
    requestData,
  } = useArticles();
  useEffect(() => {
    requestData();
  }, [requestData]);
  if (isError) {
    console.error({ articles });
    return <div className={classes.container}>Error</div>;
  }
  return (
    <Grid
      container
      justify={"center"}
      className={classes.container}
      spacing={5}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        articles.map((art) => (
          <ArticleCard
            key={art.id}
            title={art.title}
            url={art.url}
            imageURL={art.img}
          />
        ))
      )}
    </Grid>
  );
};

export default ArticlesList;
