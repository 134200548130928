import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import backCover from "../../img/contraPortada.jpeg";
import lucyHistory  from"../../img/bookTop.png";
import buyOnline  from"../../img/bookBottom.png";

const useStyles = makeStyles((theme) => ({

  containerReverse: {
    paddingTop: theme.spacing(7),
    backgroundColor: "#faf1e9",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    },
  },
  containerReverseGreen: {
    paddingTop: theme.spacing(7),
    backgroundColor: "#093a1d",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    },
  },
  sectionFirstNude: {
    backgroundColor: "#faf1e9",
    height: "auto",
    display:"flex", // // // // // // 
    justifyContent:"center",
    alignItems:"center",
    padding: "60px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px 0px 0px",
    },
  },
  textMidWhite: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "80%", // // // // // // 
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
      textAlign: "center",
    },
  },
  paragraphGreen: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
  },
  sectionMidGrayTs: {
    backgroundColor: "#faf1e9",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"center",
    alignItems:"flex-end",
    padding: "60px 0px",
    [theme.breakpoints.down("sm")]: {
      alignItems:"center",
      padding: "0px 0px 60px 0px",
    },
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  textMidGreen: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    // width: "80%", // // // // // // 
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  subTitleGreen: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // fontSize: 20,
    textAlign: "left",
    color: "#093a1d",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subTitleWhite: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    // fontSize: 20,
    textAlign: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  presentationTitleGreen: {
    textAlign: "left",
    color: "#093a1d",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "0px",
    },
  },
  presentationTitleWhite: {
    textAlign: "center",
    color: "#FFFFFF",
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(6),
    },
  },
  contentWhiteLast: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: "#FFFFFF",
  },
  sectionBefWhite: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"flex-start",
    alignItems:"center",
    // paddingTop: "40px",
  },
  textMidWhiteCoa: {
    color: "#093a1d",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    width: "80%", // // // // // // 
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  paragraphGreenCoa: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
    color: "#093a1d",
    marginBottom: "25px",
  },
  paragraphGreenSimp: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(4),
    // fontSize: 20,
    // textAlign: "center",
    color: "#093a1d",
  },
  sectionLastWhite: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"flex-start",
    alignItems:"center",
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: "25px",
    // },
  },
  sectionMidGreenTs: {
    backgroundColor: "#093a1d",
    height: "auto",
    display:"flex", // // // // // // 
    flexDirection: "column",
    justifyContent:"center",
    alignItems:"flex-end",
    padding: "0px 0px 60px 0px",
    [theme.breakpoints.down("sm")]: {
      alignItems:"center",
      padding: "0px",
    },
  },
  sectionFirstGreen: {
    backgroundColor: "#093a1d",
    height: "auto",
    display:"flex", // // // // // // 
    justifyContent:"center",
    alignItems:"center",
    padding: "0px 0px 60px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  contentWhite: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    backgroundColor: "#FFFFFF",
  },
  wrapImgCoa: {
    margin: "0px auto 0px auto",
  },

}));
const BookSection = () => {
  const classes = useStyles();
  return (

    <div>

        <Grid container justify={"center"} className={classes.containerReverse}>

            <Grid item xs={12} md={6} className={classes.sectionMidGrayTs}>
                <Grid item xs={8} >
                    <img src={lucyHistory} alt="img" className={classes.img} />
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.sectionFirstNude}>
                <Grid item className={classes.textMidWhite}>
                    <Typography component={"p"} className={classes.subTitleGreen}>DEL TRAUMA A LA RESILIENCIA</Typography>
                    <Typography variant={"h3"} className={classes.presentationTitleGreen}>La historia de Lucy</Typography>   
                </Grid>
            </Grid>  

        </Grid>


        <Grid container className={classes.contentWhiteLast} justify={"center"}>

            <Grid item xs={12} md={6} className={classes.sectionBefWhite}>
                <Grid item className={classes.textMidWhiteCoa}>
                    <Typography component={"p"} className={classes.paragraphGreenCoa}>Hace algún tiempo empecé a descubrir mi amor por la escritura, y lo terapéutico que puede llegar a ser. Hoy, despues de 10 meses escribiendo tengo el honor de haber publicado mi primer libro, llamado. La Historia de Lucy, concretando así una meta Personal y profesional.</Typography>
                    <Typography component={"p"} className={classes.paragraphGreenCoa}>Es Personal... porque se trata de mi propia historia, con altos y bajos, donde cada vivencia fue rememorada en un trabajo psicoterapéutico de 17 años, y haber publicado es en cierta forma, un desenlace a lo que yo llamo pasar de víctima a sobreviviente.</Typography>       
                </Grid>
            </Grid>  

            <Grid item xs={12} md={6} className={classes.sectionLastWhite}>
                <Grid item className={classes.textMidWhiteCoa}>
                    <Typography component={"p"} className={classes.paragraphGreenCoa}>Es Profesional... porque pretendo dar explicación desde mis reflexiones teóricas a muchas consecuencias que se generan en un cuerpo que ha sido abusado; cosas referentes al trauma complejo, estrés post-traumático y abuso sexual en las infancias per sé.</Typography>
                    <Typography component={"p"} className={classes.paragraphGreenSimp}>Vas a encontrar la vivencia narrada en detalles, lo que pasa después en mi historia, con detenimiento en algunos aspectos que engloban el pensamiento popular al rededor de la violación. También, aspectos desde la psicología de la persona abusada, su sexualidad, el efecto familiar y algunas pautas a seguir para la prevención y la reparación.</Typography>       
                </Grid>
            </Grid>  

        </Grid>


        <Grid container justify={"center"} className={classes.containerReverseGreen}>

            <Grid item xs={12} md={6} className={classes.sectionMidGreenTs}>
                <Grid item xs={10} >
                    <img src={buyOnline} alt="img" className={classes.img} />
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={classes.sectionFirstGreen}>
                <Grid item className={classes.textMidWhite}>
                    <Typography variant={"h3"} className={classes.presentationTitleWhite}>Compralo online</Typography>
                    <div style={{display:"flex",justifyContent:"space-around"}}>
                        <a href={"https://forms.gle/YUgAF9MAbW2hgnkK7"} style={{textDecoration:"none"}}>
                            <div style={{marginRight:"14px", cursor:"pointer"}}>
                                <Typography variant={"h4"} className={classes.subTitleWhite}>Venezuela</Typography> 
                                <Typography variant={"h4"} className={classes.subTitleWhite}>Argentina</Typography> 
                            </div>
                        </a>
                        <a href={"https://payhip.com/b/3e1U"} style={{textDecoration:"none"}}><Typography variant={"h4"} className={classes.subTitleWhite}>Resto del mundo</Typography></a> 
                    </div>
                </Grid>
            </Grid>  

        </Grid>


        <Grid container className={classes.contentWhite} justify={"center"}>
            <Grid item xs={12} md={10}>
                <Grid item xs={10} sm={6} md={4} className={classes.wrapImgCoa}>
                    <img src={backCover} alt="img" className={classes.img} />
                </Grid>
            </Grid>
        </Grid>


    </div>


  );
};

export default BookSection;
