import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: "50%",
    height: theme.spacing(20),
    width: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },
  alignText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(3),
  },
}));

const ArticleCard = ({ title, imageURL, url }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={5}> 
      <Card>
        <CardActionArea href={url} target="_blank">
          <Grid container className={classes.container}>
            <Grid item xs={12} align={"center"}>
              <CardMedia
                className={classes.image}
                component="img"
                alt={title}
                height="140"
                image={imageURL}
                title={title}
              />
            </Grid>
            <Grid item xs={12}  className={classes.alignText}>
              <CardContent>
                <Typography variant={"h5"} component={"h5"}>
                  {title}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ArticleCard;
