import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ArticlesList from "./components/ArticlesList";
import { containerStyles, sectionTitle } from "../../styles/common";

const useStyles = makeStyles((themes) => ({
  container: containerStyles(themes),
  sectionTitle: sectionTitle(themes),
  flexCenter: {justifyContent:"center", paddingBottom: themes.spacing(3)},
  titleContainer: {
    color: "#093a1d",
    display: "flex",
    textAlign: "left",
    width: "65%", // // // // // // 
    maxWidth:"65%",
    [themes.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
      maxWidth:"95%",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  title: {
    [themes.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
  }
}));

const Articles = () => {
  const classes = useStyles();
  return (
    <Grid container className={[classes.container,classes.flexCenter]}>
      <Grid item xs={12} className={[classes.titleContainer,classes.sectionTitle]}>
        <Typography variant={"h3"} className={classes.title}>
          Artículos
        </Typography>
      </Grid>
      <ArticlesList />
    </Grid>
  );
};

export default Articles;
