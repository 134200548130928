import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import picBanner from "../../img/picBanner.jpg";
import quote from "../../img/quote.png";

const useStyles = makeStyles((theme) => ({
  quote:{ // // // // // // img quote
    width: theme.spacing(6), // // // // // //
    margin:"-20px 5px 0px 0px",
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(4), // // // // // // 
    },
  },

  wrapTop:{ // // // // // // wrap headerImage and phrasetop #faf1e9
    marginTop: theme.spacing(8), // // // // // // down image bit
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7), // // // // // // up image lil bit
    },
  },
  imageTop: {
    backgroundImage: `url('${picBanner}')`,
    backgroundPosition: "center" /* Center the image */,
    backgroundRepeat: "no-repeat" /* Do not repeat the image */,
    backgroundSize: "cover",
    height: "60vh",
    color: "white",
  },
  titleContainer: {
    color: "#093a1d",
    display: "flex",
    textAlign: "left",
    width: "90%", // // // // // // 
    [theme.breakpoints.down("xs")]: {
      width: "95%", // // // // // // 
    },
  },
  // // // // phrase top
  phraseTop: {
    height: "60vh",
    backgroundColor: "white",
    display:"flex", // // // // // // 
    justifyContent:"center",
    alignItems:"center",
  },

}));


const HeaderImage = ({ text }) => {
  const classes = useStyles();
  return (
  <Grid container spacing={0} className={classes.wrapTop}>

    <Grid
      item
      className={classes.phraseTop}
      // alignItems={"center"}
      // justify={"center"}
      xs={12} md={5} // 

    >
      <Grid item className={classes.titleContainer}>
        <Grid>
          <img
          className={classes.quote}
            //style={{ height: "30px" }}
            src={quote}
            alt="quote"
          />
        </Grid>
        <Typography variant={"h3"}>{text}</Typography>
      </Grid>
    </Grid>

  <Grid
    item
    className={classes.imageTop}
    // alignItems={"center"}
    // justify={"center"}
    xs={12} md={7} // 

    >
    
  </Grid>

</Grid>
  );
};

export default HeaderImage;
